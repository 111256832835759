.score {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 10px;
    margin-bottom: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    transition: transform 0.3s;

    &.high-score {
        background-color: #27ae60;
        color: #fff;
        transform: scale(1.05);
    }

    &.current-score {
        background-color: #00A6ED;
        color: #fff;
        transform: scale(1.05);
    }

    .score-container {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .score-label {
            font-weight: bold;
            margin-right: 10px;
            font-size: 1.25rem;
        }

        .score-details {
            display: flex;
            align-items: center;

            .strike-value {
                font-size: 1.25rem;
                font-weight: bold;
            }

            .strike-icon {
                display: flex;

                img {
                    width: 30px;
                    height: 30px;
                    margin-left: 5px;
                    margin-top: 3px;
                }
            }
        }
    }

    .rank {
        font-weight: bold;
        font-size: 1rem;
    }
}

@media only screen and (max-width: 500px) {
    .score {
        .score-container {
            .score-label {
                font-size: 1rem;
            }

            .score-details {
                .strike-value {
                    font-size: 1rem;
                }
            }
        }

        .rank {
            font-size: 1rem;
        }
    }
}

@media only screen and (max-width: 420px) {
    .score {
        margin: 7px;
        padding: 10px;
        margin-bottom: 0;

        .score-container {
            margin-bottom: 5px;

            .score-label {
                font-size: 0.75rem;
            }

            .score-details {
                .strike-value {
                    font-size: 0.75rem;
                }

                .strike-icon {
                    img {
                        width: 23px;
                        height: 23px;
                    }
                }
            }
        }

        .rank {
            font-size: 0.75rem;
        }
    }
}