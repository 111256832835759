:host,
:root {
    --rem: 16;
}

body {
    font-family: helvetica, arial, sans-serif;
    background: #fff;
    position: relative;
    margin: 0;
    padding: 0px;
    height: 100%;
}