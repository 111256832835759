#header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    padding: 0px 16px;
    height: 40px;
    border-bottom: 1px solid #ccc;

    .placeholder {
        width: 100px;
    }

    h1 {
        flex-grow: 2;
        font-family: 'Comic Sans MS';
        font-weight: 700;
        font-size: 24px;
        letter-spacing: .01em;
        text-align: center;
        left: 0;
        right: 0;
        pointer-events: none;
        position: relative;
        width: 100px;
    }

    menu {
        display: flex;
        justify-content: flex-end;
        padding: 0;
        width: 100px;

        button {
            background: none;
            border: none;
            cursor: pointer;
            padding: 0 4px;

            img {
                height: 24px;
                width: 24px;

                &.leaderboard {
                    height: 27px;
                    width: 27px;
                }
            }
        }
    }
}


@media only screen and (max-width: 500px) {
    #header {
        .placeholder {
            width: 0;
        }

        h1 {
            text-align: left;
        }
    }

}

@media only screen and (max-width: 370px) {
    #header {
        padding: 0 10px;

        .placeholder {
            width: 0;
        }

        h1 {
            font-size: 20px;
            text-align: left;
        }

        menu {
            button {
                padding: 0 3px;

                img {
                    height: 22px;
                    width: 22px;

                    &.leaderboard {
                        height: 25px;
                        width: 25px;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 768px) {
    #header {
        height: 65px;
        padding: 0 20px;

        .placeholder {
            width: 500px;
        }

        h1 {
            width: 500px;
            font-size: 32px;
            text-align: center;
        }

        menu {
            width: 500px;

            button {
                padding: 0 8px;

                img {
                    height: 28px;
                    width: 28px;

                    &.leaderboard {
                        height: 31px;
                        width: 31px;
                    }
                }
            }
        }
    }

}