.message {
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #ffd500;
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    .ready {
        display: flex;
        text-align: center;
        padding-right: 1rem;
        padding-left: 1rem;
        font-weight: 700;

        h3 {
            font-size: 1.25rem;
            font-weight: bold;
            line-height: 1.75rem;
        }
    }

    .show-counter-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h3 {
            font-weight: 700;
            font-size: 1.25rem;
            line-height: 0;
            margin-bottom: 10px;
        }

        .show-counter {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            font-weight: 700;
            font-size: 1.25rem;
            line-height: 1.75rem;
            padding: 0.5rem;
            padding-top: 0;
            padding-bottom: 0;
            margin-top: 0;
            text-decoration: none;
            color: #000;

            .countdown {
                line-height: 1.25rem;
                padding: 0.75rem;
                align-items: center;
                display: flex;
                flex-direction: column;

                .value {
                    margin: 0;
                    text-transform: uppercase;
                    font-size: 1rem;
                    line-height: 1rem;
                }

                .type {
                    margin: 0;
                    text-transform: uppercase;
                    font-size: 0.75rem;
                    line-height: 1rem;
                }

                .danger {
                    color: #ff0000;
                }
            }
        }
    }
}