#flipper {
    display: flex;
    align-items: center;
    margin: 0 auto;
    height: 100%;
    max-width: 100%;

    #coin {
        width: 100%;
        height: 100%;
        position: relative;
        transform-style: preserve-3d;
        transition: transform 1s ease-in;

        img {
            object-fit: contain;
        }

        &.animation-heads-heads {
            animation: flipHeadsHeads 5s ease-out forwards;
        }

        &.animation-tails-tails {
            animation: flipTailsTails 5s ease-out forwards;
        }

        &.animation-heads-tails {
            animation: flipHeadsTails 5s ease-out forwards;
        }

        &.animation-tails-heads {
            animation: flipTailsHeads 5s ease-out forwards;
        }

        &.heads {
            transform: rotateX(0deg);
        }

        &.tails {
            transform: rotateX(180deg);
        }

        .side-a,
        .side-b {
            width: 100%;
            height: 100%;
            position: absolute;
            backface-visibility: hidden;
            transform: translateZ(1px); // Center the flip animation
        }

        .side-a {
            transform: rotateX(0deg);
        }

        .side-b {
            transform: rotateX(180deg);
        }
    }
}

@keyframes flipHeadsHeads {
    0% {
        transform: rotateX(0deg);
    }

    100% {
        transform: rotateX(2880deg);
    }
}

@keyframes flipHeadsTails {
    0% {
        transform: rotateX(0deg);
    }

    100% {
        transform: rotateX(2700deg);
    }
}

@keyframes flipTailsHeads {
    0% {
        transform: rotateX(180deg);
    }

    100% {
        transform: rotateX(2880deg);
    }
}

@keyframes flipTailsTails {
    0% {
        transform: rotateX(180deg);
    }

    100% {
        transform: rotateX(2700deg);
    }
}