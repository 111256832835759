.game {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;

    .section-score,
    .section-flipper,
    .section-score,
    .section-message {
        margin-left: 1vw;
        margin-right: 1vw;
        padding-top: 1.5vh;
        text-align: center;
    }

    .section-score {
        padding-top: 0.5vh;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100vw;
    }

    .section-flipper {
        text-align: justify;
        height: 50vh;
        width: 100vw;
    }
}

@media only screen and (min-width: 500px) {
    .game {
        .flipper {
            width: 500px;
        }

        .section-score {
            width: 500px;
        }
    }
}