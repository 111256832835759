.login {
    flex: 1 1 auto;
    margin: 2.5em auto 0;
    padding: 0 1em;
    max-width: 450px;
    display: block;
    font-size: 1em;
    line-height: normal;

    .header {
        h1 {
            color: black;
            font-family: georgia, "times new roman", times, serif;
            font-size: 30px;
            font-weight: 400;
            line-height: 40px;
            margin: 0;
            padding: 0;
            text-align: center;
        }
    }

    .fieldset {
        display: block;
        margin-inline-start: 2px;
        margin-inline-end: 2px;
        padding-block-start: 0.35em;
        padding-inline-start: 0.75em;
        padding-inline-end: 0.75em;
        padding-block-end: 0.625em;
        min-inline-size: min-content;
        position: relative;
        padding: 16px 0px 0px;
        border: none;
        margin: 0px;
        display: block;

        .label {
            color: rgb(0, 0, 0);
            font-size: 14px;
            font-weight: 600;
            line-height: 21px;
            display: block;
        }

        .field-wrapper {
            margin: 0px;
            padding: 0.25em 0px 0px;
            position: relative;

            .field-box {
                border: 1px solid rgb(136, 136, 136);
            }

            .field-box-focused {
                border: 1px solid rgb(0, 0, 0);
            }

            .field-box,
            .field-box-focused {
                margin: 0px;
                padding: 0px;
                border-radius: 3px;
                box-sizing: border-box;
                transition: border-color 0.15s ease-in 0s;
                outline: none;
                opacity: 1;

                .input {
                    width: 100%;
                    height: 2.75rem;
                    padding: 0px 0.75rem;
                    border-radius: 3px;
                    font-family: inherit;
                    font-size: 1rem;
                    font-weight: 500;
                    letter-spacing: normal;
                    color: rgb(0, 0, 0);
                    background-color: rgb(255, 255, 255);
                    outline: none;
                    box-sizing: border-box;
                    overflow: hidden;
                    border: 0px;
                    appearance: none;
                    opacity: 1;
                    box-shadow: none;
                    transition: box-shadow 200ms linear 0s;

                    &:focus {
                        box-shadow: none;
                    }
                }
            }
        }
    }

    .alert-icon {
        float: left;
        margin-top: 8px;
        width: 16px;
        height: 16px;
    }

    .error-message {
        display: block;
        padding: 0.0625em 0px 0.125em;
        overflow: hidden;
        transition: all 200ms ease 0s;
        transform: scaleY(1) translateY(0px);
        max-height: 100px;
        opacity: 1;
        font-size: 14px;
        line-height: 21px;
        font-weight: 500;
        font-family: Helvetica, sans-serif;
        margin: 4px 0px 0.125em 24px;
        color: rgb(166, 23, 23);
    }

    .button-wrapper {
        margin: 0px;
        width: 100%;
        padding-top: 16px;

        .submit-button {
            position: relative;
            margin: 0px;
            padding: 0px;
            width: 100%;
            height: 44px;
            line-height: 2.375em;
            font-family: helvetica, arial, sans-serif;
            font-size: 1em;
            font-weight: 700;
            cursor: pointer;
            border: 0.0625em solid rgb(18, 18, 18);
            border-radius: 3px;
            box-sizing: border-box;
            user-select: none;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            background-image: none;
            color: rgb(255, 255, 255);
            background-color: rgb(18, 18, 18);
            transition: all 0.15s ease-in 0s;

            &:hover {
                background-color: rgb(51, 51, 51);
                border-color: rgb(51, 51, 51);
                color: rgb(255, 255, 255);
            }

            &:active {
                transition: all 0s ease 0s;
                background-color: rgb(102, 102, 102);
                border-color: rgb(102, 102, 102);
            }
        }
    }


    .or-line {
        font-size: 14px;
        margin: 12px 0 0;
        line-height: 21px;
        font-weight: 500;
        font-family: helvetica,
            arial,
            sans-serif;
        position: relative;
        text-align: center;

        span {
            display: inline-block;
            height: 14px;
        }

        &::before,
        &::after {
            position: absolute;
            display: inline-block;
            content: "";
            top: 50%;
            width: calc(50% - 1em);
            height: 0px;
            border-bottom: 1px solid rgb(226, 226, 226);
        }

        &::before {
            left: 0;
            margin-right: 0.5em;
        }

        &::after {
            right: 0;
            margin-left: 0.5em;
        }
    }

    .providers-login {
        margin: 0px;
        padding: 11px 0px 0px;

        .provider-login-button-wrapper {
            display: flex;
            flex-direction: row;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            margin: 0px;
            padding-top: 0.5em;
            animation-name: unset;
            transition-timing-function: ease-in-out;
            animation-duration: 0.4s;

            .provider-login-button {
                display: block;
                height: 44px;
                line-height: 24px;
                position: relative;
                padding: 1px;
                width: 100%;
                border-radius: 3px;
                text-align: center;
                overflow: inherit;
                box-sizing: border-box;
                text-decoration: none;
                cursor: pointer;
                font-family: nyt-franklin;
                font-size: 1em;
                background-color: rgb(255, 255, 255);
                border: 1px solid rgb(0, 0, 0);

                &:hover {
                    background-color: rgb(247, 247, 247);
                }

                &:active {
                    transition: all 0s ease 0s;
                    color: rgb(118, 118, 118);
                }

                .provider-login-button-icon,
                .provider-login-button-text {
                    pointer-events: none;
                    color: rgb(18, 18, 18);
                }

                .provider-login-button-icon {
                    margin-right: 0.25em;

                    img {
                        margin-top: -3px;
                        vertical-align: middle;
                    }

                    .google-icon {
                        height: 18px;
                        width: 18px;
                    }
                }

                .provider-login-button-text {
                    display: inline-block;
                    font-weight: 600;
                    font-size: 1em;
                    left: calc(50% - 4.625em + 13px);
                    top: calc(50% - 0.5em + 1px);
                    margin-left: 8px;
                }
            }
        }
    }
}